@import "../../custom";

.home-loading {
  background-color: #fff;
}

.home-logo {
  margin: 100px auto;
}

.store-list {
  display: flex;
  flex-wrap: wrap;
}

.store {
  display: flex;
  margin: 10px;
  border-radius: 10px;
  padding: 5px;
  width: 400px;
}

.store-list-title {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.store-img {
  margin-right: 4px;
}

.store-img img {
  width: 90px;
  height: 90px;
  border: 0.5px solid #f7f3f3;
}

.store-body {
  background-color: #fff;
  padding: 5px;
  flex: 2;
}

.store-filter {
  border-bottom: 1px solid #f9fafb;
  margin-bottom: 5px;
}

.store-filter ul {
  list-style: none;
}

.store-filter li {
  display: inline-block;
  padding: 10px;
}

.store-filter a {
  color: #000;
  font-weight: 300;
  text-decoration: none;
  padding-bottom: 10px;
}

.active a,
a.active:hover {
  color: #700b72;
  font-weight: 500;
  text-decoration: none;
  padding-bottom: 10px;
  border-bottom: 2px solid #700b72;
}

.store-item-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: #222222;
  display: block;
}

.store-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.store-category-link:hover {
  color: #ccc;
}

.store-description {
  font-weight: 400;
  color: #000;
}

.delivery-logo {
  width: 64px;
  padding: 5px 0;
  margin-right: 20px;
}

.delivery-find {
  padding-top: 8px;
  flex: 2;
}

.delivery-find input {
  height: 40px;
  width: 360px;
  background: rgba(34, 34, 34, 0.05);
  border: 0.5px solid rgba(34, 34, 34, 0.05);
  border-radius: 4px;
}

.delivery-localization {
  flex: 1;
  padding: 10px 0;
}

.menu-navbar {
  list-style: none;
  display: inline-flex;
  width: 200px;
}

.menu-navbar li {
  padding: 10px;
}

.image-store {
  width: 310px;
  border-radius: 5px;
}

.title-store {
  font-size: 12px;
  font-weight: 600;
}

.page-title {
  padding: 10px 20px;
}

.btn-order-now {
  width: 140px;
  height: 25px;
  padding-top: 2px;
  margin-top: 5px;
  margin-bottom: 5px;

  text-align: center;

  background-color: #E11368;
  color: #fff;
  border-radius: 20px;

  a {
    color: #fff;
    text-decoration: none;
    font-size: 0.800rem;
  }

  a:hover {
    color: #292828;
  }
}
