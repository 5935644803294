@import "../../App.scss";

.header-store {
  height: 400px;
  background-image: url("hero_general.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #000;
  width: 100%;
  height: 100px;

  @include media-breakpoint-up(sm) {
    height: 200px;
  }
}

.header-store-title {
  font-size: 16px;
  font-weight: 600;
  bottom: 0;
  color: #000;
  background-color: #fff;
  height: 40px;
  margin-left: 10px;
  margin-top: 10px;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  max-width: 70%;
}

.header-store-whatsapp {
  font-size: 12px;
  display: block;
  background-color: #fff;
  padding: 5px 20px;
  margin-left: 10px;
  margin-top: 5px;
  width: 160px;
}

.header-store-whatsapp a {
  text-decoration: none;
  color: #000;
}

.whatsapp-logo {
  height: 20px;
}
