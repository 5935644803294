.product-items {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.product-item {
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
  margin: 5px 10px;
  display: flex;
}

.product-image {
  margin: 5px;
}

.product-title {
  font-size: 1rem;
  font-weight: 500;
  color: #222222;
}

.product-price-description {
  font-size: 14px;
  font-style: normal;
  line-height: 19px;
}
