@import "../../custom";

.footer {
  background-color: #252323;
  color: #fff;
  border-top: 10px solid $primary;
}

.footer h2 {
  padding-top: 20px;
}

.footer a {
  font-size: 0.800rem;
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  color: #fff;
  font-weight: bold;
}

.footer ul {
  padding-left: 0;
}

.footer li {
  list-style: none;
}

.footer p {
  color: #fff;
  font-size: 0.800rem;
}
