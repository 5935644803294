@import "../../App.scss";

.store-category-link {
  padding: 12px 20px;
  border: 1px solid $primary;
  border-radius: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  font-size: 0.900rem;
  background-color: #fff;
  color: $primary;

  // Box model
  height: 120px;
  width: 100px;
}


.store-category-content {
  // Box model
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.store-category-name {
  // Typography
  font-size: 0.8rem;

  // Visual
  margin-top: 10px;
}
