$primary: #6A1D90;
$danger: #800020;
$info: #0d6efd;

$theme-colors: (
  "info": $info,
  "danger": $danger,
  "primary": $primary,
);

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

body {
  color: rgba(34, 34, 34, 0.8);
  background-color: #f9fafb;

  font-family: 'Inter', sans-serif;
  font-size: 0.9rem;
}

h1 {
  font-size: 2rem;
  font-weight: 600;
  padding: 10px 0;
  margin: 0;
}

h2 {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 10px 0;
  margin: 0;
}

h3 {
  font-size: 1rem;
}

h5 {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
  color: rgba(34, 34, 34, 0.8);
}

.title-section {
  margin-top: 20px;
  color: #222;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: 19px;
}

.btn {
  border-radius: 4px;
  font-size: 0.8rem;
}

.btn-outline-primary {
  border: 2px solid;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.content {
  background-color: #fff;
  padding: 12px;
  margin: 5px;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.form-label {
  font-size: 1em;
}

.breadcrumb-container {
  background-color: $primary;
}

.breadcrumb {
  padding: 10px;
}

.breadcrumb-item a, li {
  color: #fff;
  text-decoration: none;
  font-size: 0.900rem;
}

.breadcrumb .active {
  color: #fff;
}
