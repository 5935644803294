@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.header-categories {
  background-color: #ffffff;
  border-bottom: 7px solid #ededed;
  padding: 20px 0;
}

.header-categories-menu {
  list-style: none;
  overflow: scroll;
  white-space: nowrap;
  margin: 0;
  padding-left: 0;

  @include media-breakpoint-up(sm) {
    overflow: hidden;
  }
}

.header-categories-menu li {
  margin: 0 5px;
  height: 30px;
  overflow : hidden;
  display : inline-block;
  white-space: nowrap;
}

.header-categories-menu a {
  color: #444;
  font-size: 12px;
  padding: 5px 15px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}
