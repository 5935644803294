@import "../../custom.scss";

.header {
  background: #ffff;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  color: #040a1c;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  border: 1px solid #ededed;
}

.bg-navbar {
  background-color: #fff;
  margin-bottom: 5px;
}

.icon-bag {
  width: 32px;
  height: 32px;
}

.header-logo {
  width: 120px;
}

.header-logo-img {
  width: 70px;
}

.header-title {
  width: 250px;
}

.header-actions {
  padding: 12px 5px;
  flex: 1;
}

.menu li {
  display: inline-flex;
  padding-left: 10px;
}

.welcome-login {
  font-size: 0.8rem;
  display: flex;
  padding: 8px;
}

.welcome-logout {
  text-decoration: none;
  font-weight: 400;
  color: $primary;
}

.account-actions {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}

.account-actions li {
  display: inline;
}

.account-actions a {
  text-decoration: none;
  padding: 5px;
}

.basket-link {
  margin-top: 5px;
}

.basket-link img {
  margin-left: 10px;
}

.account-authenticate {
  display: flex;
}
