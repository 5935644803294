.addresses-list {
  padding: 4px;
  color: rgba(34, 34, 34, 0.8);

  span {
    font-size: 14px;
    color: #000;

    display: block;
  }
}

.addresses-list li {
  list-style: none;
  margin-bottom: 10px;
  padding: 10px;
  height: 130px;
}

.addresses-list .title {
  display: block;
  padding-bottom: 2px;
  font-size: 16px;
}

