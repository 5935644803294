@import "custom";

p {
  font-size: 14px;
  font-weight: 400;
  color: #252323;
  margin-bottom: 10px;
}

strong {
  color: rgba(34, 34, 34, 0.8);
}

main {
  min-height: 800px;
}

.red {
  color: #df0209;
}

.form-control {
  border: 2px solid rgba(34, 34, 34, 0.1);
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: rgba(34, 34, 34, 0.4);
}

.form-label {
  font-size: 0.8em;
  font-weight: 500;

  line-height: 1em;
}

.container-fluid {
  padding: 0;
}

.alert p {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.container-content {
  background-color: #fff;

  padding: 8px 16px;
  margin: 2px;
}
