@import "../../App.scss";

.orders-list__items {
  // border: 4px solid red;
  display: grid;

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}

.orders__item {
  // border: 4px solid blue;
  height: 100%;
}

.order-card {
  border: 2px solid #f2f2f2;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.order-card-finished {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;
  // border: 4px solid green;
}

.order-card-finished__merchant {
  border-bottom: 1px solid $primary;
  display: flex;
  padding-bottom: 10px;
}

.order-merchant {
  display: flex;
}

.order-merchant__logo {
  position: relative;

  img {
    width: 40px;
    height: 40px;
  }
}

.order-merchant__details-wrapper {
  padding-left: 12px;
  // border: 4px solid pink;
}

.order-merchant__restaurant-name {
  font-weight: bold;
  color: #3f3e3e;
}

.order-card-finished__items {
  min-height: 60px;

  .item {
    display: flex;
    margin-top: 5px;
  }

  .item-qtd-wrapper {
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;

    .item-qtd-text {
      width: 20px;
      height: 20px;
      font-size: 0.9em;
      border-radius: 50%;
      background-color: #f2f2f2;
      text-align: center;
    }
  }
}
