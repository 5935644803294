@import "../../custom.scss";

.product-items {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.product-item {
  background-color: #fff;
  box-sizing: border-box;
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.product-text {
  width: 100%;

  padding: 5px 10px;
}

.product-item:hover {
  text-decoration: none;
}

.product-image {
  margin: 5px;
  width: 100px;
  height: 100px;
}

.product-image img {
  width: 89px;
  height: 89px;
  border-radius: 2px;
}

.product-title {
  font-size: 16px;
  color: #222222;
}

.product-description {
  font-size: 0.9000rem;
  color: #000
}

.product-price-description {
  font-size: 14px;
  font-style: normal;
  line-height: 19px;
  color: #000;
}

.product-price-container {
  display: flex;
  padding: 4px;
}

.product-price-value {
  font-size: 1rem;
  font-weight: 600;
  color: #674188;
}

.product-checkout-items {
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.checkout-container {
  background-color: #fff;
  border-bottom: 4px solid #efefef;
  margin-bottom: 10px;
}

.checkout-address-delivery {
  background-color: #fff;
  border-bottom: 4px solid #efefef;
  margin: 5px 0;
}

.checkout-items {
  list-style: none;
  padding: 10px;
}

.checkout-item {
  display: block;
  justify-content: space-between;
  margin-bottom: 10px;

  color:#000;
}

.checkout-items-order-list {
  padding-left: 20px;
  margin-bottom: 20px;
}

.checkout-items-order-list li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 12px;
}

.checkout-item-name {
  font-weight: 500;
  font-size: 16px;
}

.checkout-item-description {
  display: block;
}

.checkout-item-price {
  display: block;
  font-weight: 500;
  text-align: right;
  padding: 5px;
}

.category-title {
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.checkout-subtotal {
  display: flex;
  justify-content: space-between;
}

.checkout-subtotal-label span{
  font-weight: bold;
}
