.steps-container {
  background-color: #fff;
  width: 100%;
}

.steps {
  padding: 0;
}

.steps li {
  list-style-type: none;
  width: 30%;
  color: #7d7d7d;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.steps li:before {
  width: 24px;
  height: 24px;
  content: "";
  line-height: 60px;
  display: block;
  text-align: left;
  margin: 0 auto 3px auto;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  background-color: #FF9D1E;
}

.steps li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #FF9D1E;
  top: 12px;
  left: -50%;
  z-index: 0;
}

.steps li:first-child:after {
  content: none;
}

.steps li .step-title {
  font-weight: bold;
  font-size: 14px;
  color: #222;
}

.steps li .step-subtitle {
  font-size: 12px;
}

.title-resume {
  font-size: 14px;
  font-weight: 500;
  color: rgba(34, 34, 34, 0.4);
  line-height: 19px;
  text-transform: uppercase;
  margin: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(34, 34, 34, 0.08);
}

.order-list {
  list-style: none;
  padding-left: 0;
}

.order-list ul {
  list-style: none;
  padding-left: 10px;
}

.order-list li {
  color: rgba(34, 34, 34, 0.4);
  font-size: 14px;
  font-weight: 500;
  padding: 5px 0;
}
